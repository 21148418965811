import React from "react";
import "./index.css";

import CtaTel from "../CTATel";
import CtaWpp from "../CTAWpp";

const CtaTelWpp = () => {
  return (
    <article id="CtaTelWpp">
      <p className="text">
        Fale conosco agora mesmo pelo WhatsApp ou Telefone e <br />
        <b>peça um orçamento</b>
      </p>

      <div className="cta">
        <CtaWpp />
        <CtaTel />
      </div>
    </article>
  );
};

export default CtaTelWpp;
