import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTelWpp from "../../components/CTATelWpp";

import logo_topo from "../../assets/logo_topo.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <figure className="logo">
            <img src={logo_topo} alt="Logo" />
          </figure>

          <h1 className="title">
            Sua Pia, Vaso ou Ralo
            <br />
            <span>Entupiu?</span>
          </h1>

          <p className="text">
            <b>Chame a Desentupidora Pioneira!</b> <br />
            Somos Especialistas em Desentupimento em geral, em especial com
            Hidrojateamento, além oferecer o serviço de Caça-vazamentos.
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">
            Serviço com agilidade e garantia de qualidade
          </h2>

          <p className="text">
            <b>A Desentupidora Pioneira</b> está no mercado há mais de 10 anos,
            entregando soluções de confiança e com garantia de qualidade aos
            seus clientes.
          </p>

          <CtaTelWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
