import "./styles.css";

import CtaTelWpp from "../../components/CTATelWpp";
import FormasPagamento from "../../components/FormasPagamento";
import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_4 from "../../assets/about2_4.png";
import Carousel from "../../components/Carousel";

const about1Gallery = [
  {
    image: about1_1,
    title: "Desentupimento com Hidrojateamento",
    text: "Realizamos o desentupimento de ralos e tubulações de esgoto, com o sistema de hidrojateamento, precisão e garantia de satisfação.",
  },
  {
    image: about1_2,
    title: "Desentupimento de vaso sanitário",
    text: "Realizamos o desentupimento de vaso sanitário com eficiência e rapidez, garantindo sua total satisfação e conforto.",
  },
  {
    image: about1_3,
    title: "Caça-vazamentos",
    text: "Realizamos o serviço de caça-vazamentos, de forma precisa, com equipamentos de alta tecnologia em seu lar ou empresa.",
  },
  {
    image: about1_4,
    title: "Desentupimento de Caixa de Gordura",
    text: "Desentupimento de esgoto com eficácia e rapidez, proporcionando soluções confiáveis e duradouras para a sua rede de saneamento, com foco na sua comodidade e bem-estar.",
  },
  {
    image: about1_5,
    title: "Desentupimento de Pias",
    text: "Realizamos o desentupimento de pias com rapidez e eficiência. Nossos profissionais qualificados, garantem o perfeito funcionamento de suas instalações.",
  },
];

const about2Gallery = [
  {
    image: about2_1,
    title: "Pontualidade",
    text: "Atendimento profissional com agilidade e excelência.",
  },
  {
    image: about2_2,
    title: "Profissionalismo",
    text: "Contamos com profissionais altamente capacitados.",
  },
  {
    image: about2_3,
    title: "Atendimento 24h",
    text: "Oferecemos atendimento 24h, com agilidade e excelência.",
  },
  {
    image: about2_4,
    title: "Atendimento personalizado",
    text: "Atendimento de acordo com a necessidade de cada cliente.",
  },
];

const About = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">CONHEÇA NOSSOS SERVIÇOS</h2>

          {isMobile ? (
            <div className="mobile">
              {about1Gallery.map((item, index) => (
                <div className="item" key={index}>
                  <figure>
                    <img src={item.image} alt={item.title} />
                  </figure>

                  <div className="bottom">
                    <p className="item_title">{item.title}</p>

                    <p className="item_text">{item.text}</p>

                    <CtaWpp text={"Solicite um orçamento"} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Carousel arr={about1Gallery} isVideo={false} slidesToShow={3} />
          )}
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">Por que escolher a desentupidora pioneira?</h2>

          <div className="gallery">
            {about2Gallery.map((item, index) => (
              <div className="item" key={index}>
                <figure>
                  <img src={item.image} alt={item.title} />
                </figure>

                <h3 className="item_title">{item.title}</h3>

                <p className="item_text">{item.text}</p>
              </div>
            ))}
          </div>

          <CtaTelWpp />
        </div>
      </article>

      <FormasPagamento />
    </section>
  );
};

export default About;
