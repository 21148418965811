import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure>
            <img src={logo_rodape} alt="Logo" />
          </figure>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            Desentupidora Pioneira © 2024 - Todos os direitos reservados
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
