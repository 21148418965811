import React from "react";
import "./styles.css";

import menu from "../../assets/menu.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <article className="left"></article>

        <article className="right">
          <figure className="left">
            <img src={menu} alt="Logo" />
          </figure>

          <p className="text">ATENDIMENTO 24H / Atendemos toda região</p>
        </article>
      </section>
    </nav>
  );
};

export default Menu;
